<template>
  <div class="terms">

        <div class="content-header row">
          <div class="content-header-left col-md-9 col-12 mb-2">
            <div class="row breadcrumbs-top">
              <div class="col-12">

                <div class="breadcrumb-wrapper col-12">
                  <ol class="breadcrumb">
                    <li class="breadcrumb-item"><a href="#">Dashboard</a>
                    </li>

                    <li class="breadcrumb-item"><a href="#">My Policies</a>
                    </li>

                    <li class="breadcrumb-item active">Policy
                    </li>
                  </ol>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-md-12">
            <hr/>
          </div>
        </div>

        <div class="row mb-2">
          <div class="col-md-3">
          </div>
          <div class="col-md-6">
            <div class="card" >
              <div class="card-content" style="background-color:#EFF5F9;">
                <div class="card-body">

                  <div class="row">
                    <div class="col-md-8">
                      <div >
                        <img src="../app-assets/images/car.png" style="max-height: 100px;margin-left: 20px;" />
                        <div class="float-right" style="margin-top: 20px;margin-right: 20px;">
                          <h4>Comprehensive Motor <br/>
                            Insurance</h4>
                          <span v-if="policy.vehicleDetails.length > 0 && policy.vehicleDetails[0].model"> {{ policy.vehicleDetails[0].model }} </span>,<span v-if="policy.vehicleDetails.length > 0 && policy.vehicleDetails[0].numberPlate"> {{ policy.vehicleDetails[0].numberPlate }}</span>
                        </div>
                      </div>
                    </div>
                    <div class="col-md-4" style="border-left: 1px solid #ccc;">
                      <div  style="margin-top: 20px;">
                        <h4>Policy Number:</h4>
                        <span>{{ policy.polNo }}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-3">
          </div>
        </div>

        <div class="row">
          <div class="col-md-12">
            <div class="card" >
              <div class="card-content">
                <div class="card-body">
                  <ul class="nav nav-tabs nav-fill" id="myTab" role="tablist">
                    <li class="nav-item">
                      <a class="nav-link active"  id="overview-tab-fill" data-toggle="tab" href="#overview" role="tab" aria-controls="home-fill" aria-selected="false">Overview</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link "  id="benefit-tab-fill" data-toggle="tab" href="#benefit" role="tab" aria-controls="profile-fill" aria-selected="true">Benefit Details</a>
                    </li>
                    <li class="nav-item">
                      <a class="nav-link "  id="insured-tab-fill" data-toggle="tab" href="#insured" role="tab" aria-controls="profile-fill" aria-selected="true">Insured Asset Details</a>
                    </li>
                  </ul>

                  <!-- Tab panes -->
                  <div class="tab-content pt-1">
                    <div class="tab-pane active" id="overview" role="tabpanel" aria-labelledby="home-tab-fill">
                      <div class="row mb-5">
                        <div class="col-md-5">
                            <div style="margin-top: 20px;margin-right: 70px;">
                              <div>
                                <span style="color:#bbe600;font-weight: bolder;">Start Date </span> <span class="float-right">{{ policy.wefDate }}</span>
                              </div>
                              <div>
                                <span style="font-weight: bolder;">Renewal Date </span> <span class="float-right">{{ policy.wetDate }}</span>
                              </div>

                            </div>
                        </div>
                        <div class="col-md-4" style="border-left: 1px solid #ccc;">
                          <div  style="margin-top: 20px;margin-left: 100px;">
                            <span>Total Annual Premium: </span>
                            <h4> {{ policy.premium | currency('KES. ', 0)  }}</h4>
                          </div>

                        </div>
                        <div class="col-md-3" style="border-left: 1px solid #ccc;">
                          <div style="margin-top: 20px;margin-left: 50px;margin-right: 50px;">
                            <img src="../app-assets/images/britam_logo.png" style="max-height:70px;" />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane " id="benefit" role="tabpanel" aria-labelledby="profile-tab-fill">
                      <div class="row mb-2">
                        <div class="col-md-6" >
                          <table class="table table-bordered table-striped">
                            <tr v-for="policy_benefit in policy_benefits">
                              <th>{{policy_benefit.shtDesc}}</th>
                              <td>{{ policy_benefit.benefit}}</td>
                            </tr>
                          </table>
                        </div>
                      </div>
                    </div>
                    <div class="tab-pane " id="insured" role="tabpanel" aria-labelledby="profile-tab-fill">
                      <div class="row">
                        <div class="col-md-6">
                          <div >
                            <img src="../app-assets/images/car.png" style="max-height: 100px;margin-left: 20px;" />
                            <div class="float-right" style="margin-top: 20px;margin-right: 20px;">
                              <h4>Comprehensive Motor <br/>
                                Insurance</h4>
                            </div>
                          </div>

                        </div>
                        <div class="col-md-6" style="border-left: 1px solid #ccc;">
                          <div style="margin-top: 20px;margin-right: 70px;margin-left: 70px;">
                            <div class="mb-2">
                              <span class="text-black text-bold" >Sum Insured </span> <span class="float-right"> {{ policy.sumInsured | currency('KES. ', 0)}}</span>
                            </div>
                            <div class="mb-2">
                              <span class="text-black text-bold" >Premium </span> <span class="float-right">{{ policy.premium | currency('KES. ', 0) }}</span>
                            </div>
                            <div class="mb-2">
                              <span class="text-black text-bold">Registration Number: </span>  <span class="float-right" v-if="policy.vehicleDetails.length > 0 && policy.vehicleDetails[0].numberPlate"> {{ policy.vehicleDetails[0].numberPlate }}</span> <br/>
                            </div>
                            <div class="mb-2">
                              <span class="text-black text-bold">Make: </span>  <span class="float-right" v-if="policy.vehicleDetails.length > 0 && policy.vehicleDetails[0].make"> {{ policy.vehicleDetails[0].make }}</span> <br/>
                            </div>
                            <div class="mb-2">
                              <span class="text-black text-bold">Model: </span>  <span class="float-right" v-if="policy.vehicleDetails.length > 0 && policy.vehicleDetails[0].model"> {{ policy.vehicleDetails[0].model }}</span> <br/>
                            </div>
                            <div class="mb-2">
                              <span class="text-black text-bold">Color: </span>  <span class="float-right" v-if="policy.vehicleDetails.length > 0 && policy.vehicleDetails[0].color"> {{ policy.vehicleDetails[0].color }}</span> <br/>
                            </div>
                            <div class="mb-2">
                              <span class="text-black text-bold">Engine Size: </span>  <span class="float-right" v-if="policy.vehicleDetails.length > 0 && policy.vehicleDetails[0].engineSize"> {{ policy.vehicleDetails[0].engineSize }}</span> <br/>
                            </div>
                            <div class="mb-2">
                              <span class="text-black text-bold">Year Of Manufacture: </span>  <span class="float-right" v-if="policy.vehicleDetails.length > 0 && policy.vehicleDetails[0].yearOfManufacture"> {{ policy.vehicleDetails[0].yearOfManufacture }}</span> <br/>
                            </div>
                            <div class="mb-2">
                              <span class="text-black text-bold">Chassis Number: </span>  <span class="float-right" v-if="policy.vehicleDetails.length > 0 && policy.vehicleDetails[0].chassisNumber"> {{ policy.vehicleDetails[0].chassisNumber }}</span> <br/>
                            </div>


                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>





  </div>
</template>

<script>

  import { mapActions } from "vuex";

  export default {
    name: 'home',
    data () {
      return {
        policy:{},
        policy_benefits:[]
      };
    },
    mounted(){
      this.getPolicyData();
      this.getPolicyBenefitsData();
    },

    methods:{
      ...mapActions(["getMyPolicy","getPolicyBenefits"]),

      getPolicyData(){
        let self = this;

        this.isLoading = true;

        //console.log(localStorage.getItem("email"));

        this.getMyPolicy({id: this.$route.params.policyId}).then((policy) => {
          self.isLoading = false;

          self.policy = policy


        }).catch(error => {
          self.isLoading = false;
          console.log(error);
        })
      },
      getPolicyBenefitsData(){
        let self = this;

        this.isLoading = true;

        //console.log(localStorage.getItem("email"));

        this.getPolicyBenefits().then((policy_benefits) => {
          self.isLoading = false;

          self.policy_benefits = policy_benefits


        }).catch(error => {
          self.isLoading = false;
          console.log(error);
        })
      }
    }
  }
</script>

<style>

  .list-group-item {
    background-color: transparent !important;
    color:#fff !important;
    padding: 5px !important;
    border:none !important;
  }

  .quote-summary{
    padding: 20px;
    background-color: #2F3641;
    border-radius: 5px;
    color:#fff;
  }

  .quote-summary h4 {
    font-size: 1.2em;
  }

  .quote-summary h4, .quote-summary h5,  .quote-success h4{
    color:#fff;
  }

  .quote-success{
    background-color:#13233A;
    border-radius: 5px;
    padding: 40px 20px;
    color:#fff;
  }

  .quote-btn{
    width: 100%;
  }

  .table th, .table td{
    padding: 7px;
  }



</style>
